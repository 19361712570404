import React from "react";
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight ,faLocationArrow} from '@fortawesome/free-solid-svg-icons'
import fb from '../../Images/Logo/fb_logo.png'
import insta from '../../Images/Logo/insta.png'
import linkedin from '../../Images/Logo/linkedin_logo.png'
import twitter from '../../Images/Logo/twitter_logo.png'
import logo from './logo-fff.png'
import moment from "moment";
function Footer(){
    return(
    <>
        <footer className="footer-section mt-5">
          <div className="footer-top">
            <div className="container">
              <div className="row">
             
                <div className="col-md-8 col-sm-6">
                  <div className="widget course-links-widget">
                  
                    <ul className="footer-courses-link-list">
                      <li><NavLink className='nav-link' to='/terms_and_conditions'>Terms & conditions</NavLink></li>
                      <li><NavLink className='nav-link' to='/complaint_policy'>Complaints Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/privacy_policy'>Privacy Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/faqs'>FAQs</NavLink></li>
                      <li><NavLink className='nav-link' to='/about-us'>About Us</NavLink></li>
                      <li><NavLink className='nav-link' to='/contact-us'>Contact Us</NavLink></li>
                    </ul>
                  </div>
                  <div className="widget newsletter-widget">
                    <h5 className="widget-title">Prestige Hotel Group</h5>
                    <div className="footer-newsletter">
                     
                      <p><FontAwesomeIcon icon={faLocationArrow}/>  4271 Zaid Bin Al Khattab, An Naim Dist. 23622 Jeddah, KSA</p>
                     
                    </div>
                  </div>
                </div>
                <div className=" col-md-4 col-sm-6">
                <div className="social-icons mt-4">
                <i class='fas fa-inst'>
              
                 </i>
                        <a className="logo_hover" href="" target="_blank"> <img src={fb} alt="Facebook Logo" width="47px"/></a>
                        <a className="logo_hover ms-4" href="" target="_blank"> <img src={insta} alt="Insta Logo" height="55px" width="55px"/></a>
                        <a className="logo_hover ms-4" href="" target="_blank"> <img src={linkedin} alt="LinkedIn Logo" height="50px" width="50px"/></a>
                        <a className="logo_hover ms-4" href="" target="_blank"> <img src={twitter} alt="Facebook Logo" width="54px"/></a></div>
                      
                </div>
              </div>
            </div> 
          </div>
        
        </footer>
    </>        
    );
}

export default Footer;