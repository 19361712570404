import React, { useState, useEffect } from 'react'
import hotelImg from '../../Images/Packages/hotel-img.jpg'
import tqwa from '../../Images/View Detail/2.jpeg'
import masher from '../../Images/Home/masher.jpg'
import elafF from '../../Images/Packages/elaf-f.jpg'
import offers from '../../Images/Packages/offers.jpg'
import Axios from 'axios'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../GlobalData/GlobalData'
import { Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchHotelDetail } from '../../Redux/Actions/actions'
import { fetchHotels, fetchHotelsSearh } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import Loading from '../Loading/Loader'
function MorePackages () {
  var token = Hotelapitoken();
  var apiendpoint = ApiEndPoint();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const[loader,setLoader]= useState(false);
  const [isloading, setLoading] = useState(false);
  const [isloadingMakkah, setLoadingMakkah] = useState(true);
  const [isloadingMadinah, setLoadingMadinah] = useState(true);
  const [makkahHotels, setMakkahHotels] = useState([]);
  const [MadinahHotels, setMadinahHotels] = useState([]);
  const [makkahDetail, setMakkahDetail] = useState({});
  const [madinahDetail, setMadinahDetail] = useState({});
  const [GetCurrency, setGetCurrency] = useState(true);
  const [baseCName, setBaseCName] = useState('GBP');
  const [showPrice, setShowPrice] = useState(true);
  const [baseCurrency, setBaseCurrency] = useState([]);
  const Dispatch = useDispatch();
  const navigation = useNavigate();
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const [t, i18n] = useTranslation();
  const language = i18n.language;
  useEffect(() => {
    getUserLocation()
  }, [])
  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        SearchMakkahhotels(data.country.name)
        SearchMadinahhotels(data.country.name)
        localStorage.setItem('usercountry', data.country.name)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }
  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/hotels/mata',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setMakkahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }

  const fetchHotelDetails1 = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/hotels/mata',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setMadinahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const SearchMakkahhotels = async userCountry => {
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const threeDaysLater = new Date(currentDate)
    threeDaysLater.setDate(currentDate.getDate() + 3)
    let Checkin = moment(twoDaysLater)
    let checkout = moment(threeDaysLater)
    let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token: token,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: 'Makkah',
      country: 'Saudi Arabia',
      lat: 21.4240968,
      long: 39.81733639999999,
      pin: 'SA',
      cityd: 'Makkah',
      country_code: 'SA',
      total_Nights: daysBetween,
      check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
      check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    try {
      Dispatch(fetchHotelsSearh(FormData))
      const response = await Axios.post(
        apiendpoint + '/api/search/hotels/new',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.removeItem('FlightCheckOut')
      response.data.hotels_list.slice(0, 4).forEach(hotel => {
        if (!makkahDetail[hotel.hotel_id]) {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        }
      })
      if (response.data.hotels_list.length !== 0) {
        setGetCurrency(false);
        const currencies = [];
        response.data.hotels_list.forEach(hotel => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny);
          }
        });
        const currencyResponse =await AllCurrency(currencies);
        const result =makeArrayNull(currencyResponse)
        if(result===null){
          localStorage.setItem('AllHotelCurr',null);
        }else{
          localStorage.setItem('AllHotelCurr',JSON.stringify(currencyResponse));
        }
        
        setBaseCurrency(currencyResponse)
      }
      setMakkahHotels(response.data)
      setLoadingMakkah(false)
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const SearchMadinahhotels = async userCountry => {
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const threeDaysLater = new Date(currentDate)
    threeDaysLater.setDate(currentDate.getDate() + 3)
    let Checkin = moment(twoDaysLater)
    let checkout = moment(threeDaysLater)
    let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token: token,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: 'Madinah',
      country: 'Saudi Arabia',
      total_Nights: daysBetween,
      lat: 24.4672132,
      long: 39.6024496,
      pin: 'SA',
      cityd: 'Madinah',
      country_code: 'SA',
      check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
      check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    try {
      Dispatch(fetchHotelsSearh(FormData))
      const response = await Axios.post(
        apiendpoint + '/api/search/hotels/new',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.removeItem('FlightCheckOut')
      response.data.hotels_list.slice(0, 4).forEach(hotel => {
        if (!madinahDetail[hotel.hotel_id]) {
          fetchHotelDetails1(hotel.hotel_id, hotel.hotel_provider)
        }
      })
      // if (GetCurrency) {
      //   AllCurrency(response.data.hotels_list[0]?.hotel_curreny)
      // }
      setMadinahHotels(response.data)
      setLoadingMadinah(false)
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const RoomDetailPage = async (id, index, city) => {
    var hotelRoomdetail = []
    if (city === 'makkah') {
      hotelRoomdetail = makkahHotels.hotels_list.filter(
        item => item.hotel_id == id
      )
      dispatch(fetchHotels(makkahHotels))
    } else if (city === 'madinah') {
      hotelRoomdetail = MadinahHotels.hotels_list.filter(
        item => item.hotel_id == id
      )
      dispatch(fetchHotels(MadinahHotels))
    }

    setLoader(true)
    try {
      const data = {
        token: token,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      const response = await Axios.post(
        apiendpoint + '/api/hotels/details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )

      // Handle the API response here
      dispatch(fetchHotelDetail(response.data.hotel_details))
      navigation(`/hotel_detail/${id}`, { state: { index } })
      setLoader(false)
    } catch (error) {
      // Handle errors here
      setLoader(false)
      console.error('Error:', error)
    }
  }
  const AllCurrency =async currencyArray => {
    const token = CurrencyConverter();
    const requests =currencyArray.length!==0 && currencyArray.map(async currencynew => {
      const config = {
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {}
      };

      try {
        const response = await Axios.request(config)
        return response.data
      } catch (error) {
        console.error(error)
        setShowPrice(false)
        return []
      }
    });
    const results = await Promise.all(requests);
    var newdatcurr=results.filter(response => response !== null);
    return newdatcurr;
  };
  function makeArrayNull(arr) {
    // Check if every element in the array is an empty array
    if (arr.every(element => Array.isArray(element) && element.length === 0)) {
      return null;
    }
    return arr;
  }
  const renderPrice = (price,currency) => {
    var currencyData= baseCurrency
    var selectedcurr=localStorage.getItem('DefaultCurrency')
    if(selectedcurr===currency|| currencyData.length===0 || currencyData===null){
      return Number(price).toFixed(0)
    }else{
      var filterroomcurrdata=currencyData.filter(item=> item.base_code===currency);
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = (Number(gbpprice) * Number(price))
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = (Number(gbpprice) * Number(price))
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = (Number(gbpprice2) * Number(baseprice1))
      }
      return baseprice.toFixed(0)
    }
  };
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2);
    }
    return finalpricemarkup
  };
  return (
    <>
    {loader && (<Loading/>)}
      <div class='container mt-5 mb-5'>
        <div className='row'>
          <div className='section-title mt-5'>
            <h2 className='text-center'>Makkah Hotels</h2>
            <p>
              Prestige hotel portfolio is popular among major tour operators due
              to its strategic location near major landmarks, as well as its
              high-end services tailored to meet the needs of travelers seeking
              authentic Arabian hospitality.
            </p>
          </div>
        </div>
        <div class='row align-items-center justify-content-between g-4'>
          {isloadingMakkah ? (
            <div className='row mt-4'>
              <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div class='theme_common_box_two img_hover'>
                  <div class='theme_two_box_img'>
                    <div
                      style={{
                        background: '#c88698',
                        height: '12em'
                      }}
                    ></div>
                  </div>
                  <div class='theme_two_box_content'>
                    <p class='card-text placeholder-glow'>
                      <span class='placeholder col-7'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div class='theme_common_box_two img_hover'>
                  <div class='theme_two_box_img'>
                    <div
                      style={{
                        background: '#c88698',
                        height: '12em'
                      }}
                    ></div>
                  </div>
                  <div class='theme_two_box_content'>
                    <p class='card-text placeholder-glow'>
                      <span class='placeholder col-7'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div class='theme_common_box_two img_hover'>
                  <div class='theme_two_box_img'>
                    <div
                      style={{
                        background: '#c88698',
                        height: '12em'
                      }}
                    ></div>
                  </div>
                  <div class='theme_two_box_content'>
                    <p class='card-text placeholder-glow'>
                      <span class='placeholder col-7'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div class='theme_common_box_two img_hover'>
                  <div class='theme_two_box_img'>
                    <div
                      style={{
                        background: '#c88698',
                        height: '12em'
                      }}
                    ></div>
                  </div>
                  <div class='theme_two_box_content'>
                    <p class='card-text placeholder-glow'>
                      <span class='placeholder col-7'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class='row g-4'>
              {makkahHotels.hotels_list.slice(0, 3).map((item, index) => (
                <div key={index} class=' col-md-4 col-sm-6'>
                  <div className='hotel-home text-center'>
                    {makkahDetail[item.hotel_id] &&
                    makkahDetail[item.hotel_id].details_data &&
                    makkahDetail[item.hotel_id].details_data.image ? (
                      <img
                        style={{ height: '15em', objectFit: 'cover' }}
                        className=' w-100'
                        src={makkahDetail[item.hotel_id].details_data.image}
                        alt='hotel-imgs'
                      />
                    ) : (
                      <img
                        style={{ height: '15em', objectFit: 'cover' }}
                        className='  w-100'
                        src={tqwa}
                        alt='hotel-imgs'
                      />
                    )}
                    <h3>{item.hotel_name}</h3>
                    <div class='rating-area mb-0'>
                      <ul class='rating'>
                        <li>
                          <p className='card-star'>
                            {item.stars_rating === '' ? (
                              <span className='fw-bold'>No Rating</span>
                            ) : (
                              Array(item.stars_rating)
                                .fill(0)
                                .map((_, index) => (
                                  <i key={index} className='fa fa-star'>
                                    <FontAwesomeIcon icon={faStar} />
                                  </i>
                                ))
                            )}
                          </p>
                        </li>
                      </ul>
                    </div>
                    {/* <p>Elaf Al-Taqwa Hotel offers a unique spiritual experience, with its distinctive panoramic view of the Prophet's Mosque and its proximity to many holy destinations, including Al-Ghamama Mosque, Abu Bakr Al-Siddiq Mosque</p> */}
                    <h2 className='mt-2'>Rate From</h2>
                    {showPrice ? (
                      <h2>
                        {renderPrice(
                          calculateMarkup(
                            item.min_price,
                            item.admin_markup,
                            item.admin_markup_type,
                            item.customer_markup,
                            item.customer_markup_type
                          ),item?.hotel_curreny
                        )}{' '}
                        {CurrencyRates === undefined? baseCName: CurrencyRates.selectedcurrency}
                      </h2>
                    ) : (
                      <h2>
                        {calculateMarkup(
                          item.min_price,
                          item.admin_markup,
                          item.admin_markup_type,
                          item.customer_markup,
                          item.customer_markup_type
                        )}{' '}
                        {item?.hotel_curreny}
                      </h2>
                    )}
                    <Button
                      onClick={() =>
                        RoomDetailPage(item.hotel_id, index, 'makkah')
                      }
                      className='book-btn-hotel'
                    >
                      BOOK NOW
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='row'>
          <div className='section-title mt-5'>
            <h2 className='text-center'>Medina Hotels</h2>
            <p>
              Prestige hotel portfolio is popular among major tour operators due
              to its strategic location near major landmarks, as well as its
              high-end services tailored to meet the needs of travelers seeking
              authentic Arabian hospitality.
            </p>
          </div>
        </div>
        <div class='row align-items-center justify-content-between g-4'>
          {isloadingMadinah ? (
            <div className='row mt-4'>
              <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div class='theme_common_box_two img_hover'>
                  <div class='theme_two_box_img'>
                    <div
                      style={{
                        background: '#c88698',
                        height: '12em'
                      }}
                    ></div>
                  </div>
                  <div class='theme_two_box_content'>
                    <p class='card-text placeholder-glow'>
                      <span class='placeholder col-7'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div class='theme_common_box_two img_hover'>
                  <div class='theme_two_box_img'>
                    <div
                      style={{
                        background: '#c88698',
                        height: '12em'
                      }}
                    ></div>
                  </div>
                  <div class='theme_two_box_content'>
                    <p class='card-text placeholder-glow'>
                      <span class='placeholder col-7'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div class='theme_common_box_two img_hover'>
                  <div class='theme_two_box_img'>
                    <div
                      style={{
                        background: '#c88698',
                        height: '12em'
                      }}
                    ></div>
                  </div>
                  <div class='theme_two_box_content'>
                    <p class='card-text placeholder-glow'>
                      <span class='placeholder col-7'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div class='theme_common_box_two img_hover'>
                  <div class='theme_two_box_img'>
                    <div
                      style={{
                        background: '#c88698',
                        height: '12em'
                      }}
                    ></div>
                  </div>
                  <div class='theme_two_box_content'>
                    <p class='card-text placeholder-glow'>
                      <span class='placeholder col-7'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-4'></span>
                      <span class='placeholder col-6'></span>
                      <span class='placeholder col-8'></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class='row g-4'>
              {MadinahHotels.hotels_list.slice(0, 3).map((item, index) => (
                <div key={index} class=' col-md-4 col-sm-6'>
                  <div className='hotel-home text-center'>
                    {madinahDetail[item.hotel_id] &&
                    madinahDetail[item.hotel_id].details_data &&
                    madinahDetail[item.hotel_id].details_data.image ? (
                      <img
                        style={{ height: '15em', objectFit: 'cover' }}
                        className=' w-100'
                        src={madinahDetail[item.hotel_id].details_data.image}
                        alt='hotel-imgs'
                      />
                    ) : (
                      <img
                        style={{ height: '15em', objectFit: 'cover' }}
                        className='  w-100'
                        src={tqwa}
                        alt='hotel-imgs'
                      />
                    )}
                    <h3>{item.hotel_name}</h3>
                    <div class='rating-area mb-0'>
                      <ul class='rating'>
                        <li>
                          <p className='card-star'>
                            {item.stars_rating === '' ? (
                              <span className='fw-bold'>No Rating</span>
                            ) : (
                              Array(item.stars_rating)
                                .fill(0)
                                .map((_, index) => (
                                  <i key={index} className='fa fa-star'>
                                    <FontAwesomeIcon icon={faStar} />
                                  </i>
                                ))
                            )}
                          </p>
                        </li>
                      </ul>
                    </div>
                    {/* <p>Elaf Al-Taqwa Hotel offers a unique spiritual experience, with its distinctive panoramic view of the Prophet's Mosque and its proximity to many holy destinations, including Al-Ghamama Mosque, Abu Bakr Al-Siddiq Mosque</p> */}
                    <h2 className='mt-2'>Rate From</h2>
                    {showPrice ? (
                      <h2>
                        {renderPrice(
                          calculateMarkup(
                            item.min_price,
                            item.admin_markup,
                            item.admin_markup_type,
                            item.customer_markup,
                            item.customer_markup_type
                          ),item?.hotel_curreny
                        )}{' '}
                        {CurrencyRates === undefined
                          ? baseCName
                          : CurrencyRates.selectedcurrency}
                      </h2>
                    ) : (
                      <h2>
                        {calculateMarkup(
                          item.min_price,
                          item.admin_markup,
                          item.admin_markup_type,
                          item.customer_markup,
                          item.customer_markup_type
                        )}{' '}
                        {item?.hotel_curreny}
                      </h2>
                    )}
                    <Button
                      onClick={() =>
                        RoomDetailPage(item.hotel_id, index, 'madinah')
                      }
                      className='book-btn-hotel'
                    >
                      BOOK NOW
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <section className='offer-section'>
        <div className='row align-items-center mt-5 gx-0'>
          <div className='col-sm-6'>
            <img className=' w-100' src={offers} alt='offers-imgs' />
          </div>
          <div className='col-sm-6'>
            <div className='ms-5'>
              <h3>SPECIAL OFFERS</h3>
              <p>
                Get the most out of your stay by exploring special rates and
                packages at our hotel.
              </p>
              <Button className='book-btn-hotel'>explore our offers</Button>
            </div>
          </div>
        </div>
        <div
          className='row align-items-center  gx-0'
          style={{ backgroundColor: '#C6AA77' }}
        >
          <div className='col-sm-6'>
            <div className='ms-5'>
              <h3>PRESTIGE  FIRST</h3>
              <p>
                At PRESTIGE  FIRST, you are our world. Everything starts with you.
                You come first. Welcome to PRESTIGE  FIRST. Immerse yourself in a
                world of exclusive benefits and privileges, specifically
                designed to cater to your individual needs and experiences.
              </p>
              <Button className='book-btn-hotel'>Join Now</Button>
            </div>
          </div>
          <div className='col-sm-6'>
            <img className=' w-100' src={elafF} alt='offers-imgs' />
          </div>
        </div>
      </section>
    </>
  )
}

export default MorePackages
