import React, { useEffect, useState } from 'react'
import img1 from '../../Images/Carousal/5.jpeg'
import '../../Components/Carousal/style.css'
import img2 from '../../Images/Carousal/6.jpeg'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import img4 from '../../Images/Carousal/4.jpg'
import Carousel from 'react-bootstrap/Carousel'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Packages from '../../Components/Packages/Packages'
import agentpic from '../../Images/Home/agentpic.png'
import adimage from '../../Images/Home/qurbani_ad.jpeg'
import Hotels from '../../Components/Hotel/Hotels'
import MorePackages from '../../Components/MorePackages/MorePackages'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Helmet from 'react-helmet'
import wow from 'wowjs'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEnvelope, faHeadphones, faMoneyBill, faPhone, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
function Home () {
  const [isLoading, setIsLoading] = useState(true)
  const [bottomAdd, setShowbottomAdd] = useState(true)
  const [modalOpen,setModalOpen]=useState(false);
  const navigate=useNavigate();
  useEffect(() => {
    new wow.WOW().init()
    
    const fetchData = async () => {
      
      await new Promise(resolve => setTimeout(resolve, 2000))
      setIsLoading(false)
    }

    fetchData()
  }, []);

  const toggleModal=()=>{
    setModalOpen(!modalOpen);
  };

  const hideadd = () => {
    setShowbottomAdd(false)
  };
  const gotoHajjPage=()=>{
    navigate('/hajj');
  }
  return (
    <>
      <Helmet>
        <title>Prestige</title>
      </Helmet>
      {isLoading && <Loader />}
      <Layout>
        <div className='overlay-container'>
          <Carousel className='carousel-container'>
            <Carousel.Item>
              <img className='d-block w-100' src={img1} alt='First slide' />
            </Carousel.Item>
            <Carousel.Item>
              <img className='d-block w-100' src={img2} alt='First slide' />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img className='d-block w-100' src={img3} alt='First slide' />
            </Carousel.Item>
            <Carousel.Item>
              <img className='d-block w-100' src={img4} alt='First slide' />
            </Carousel.Item> */}
          </Carousel>
          {/* <SearchBar /> */}
        </div>
        {/* <Packages /> */}
        {/* <Hotels/> */}
        <MorePackages />
      </Layout>
        <Modal isOpen={modalOpen} className='home_modal' toggle={toggleModal} >
        <ModalHeader toggle={toggleModal}>Al Hijaz Tours</ModalHeader>
        <ModalBody>
        <a target='blank' href='https://buy.stripe.com/7sI03q01N42Bbuw7ss'>
        <img
                class='phone-only-agent'
                width='auto'
                height='auto'
                alt='Call us at 0121 777 2522'
                src={adimage}
              /></a>
                <div className='text-center   mt-2 align-self-center'>
                <a target='blank' href='https://buy.stripe.com/7sI03q01N42Bbuw7ss'> <button className='btn btn-primary '  >Book Now</button></a>
                            </div>
                            
        </ModalBody>
      </Modal>
    </>
  )
}

export default Home
