import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import HotelDetail from '../../Pages/Hotels/HotelDetail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faGlobe,
  faCheck,
  faArrowRight,
  faInfo,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import moment from 'moment'
import iLoading from '../../Images/Loading/Loader.gif'
import Loading from '../Loading/Loader'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../GlobalData/GlobalData'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { fetchHotelDetail } from '../../Redux/Actions/actions'
function HotelCard ({ hotelid }) {
  const dispatch = useDispatch()
  var endpoint = ApiEndPoint()
  var apitoken = Hotelapitoken()
  const [searchData, setSearchData] = useState({
    checkin: '',
    checkout: '',
    adult: 0,
    child: 0
  })
  var filteredHotels = []
  const navigate = useNavigate()
  const hotels = useSelector(state => state.hotels.hotels)
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const [Loadingpage, setLoadingpage] = useState(false)
  const [visibleHotels, setVisibleHotels] = useState(10)
  const [hotelDetails, setHotelDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [baseCName, setBaseCName] = useState('GBP')
  const [baseCurrency, setBaseCurrency] = useState([])
  const [hotelsListing, setHotelsListing] = useState(hotels.hotels_list)
  const [showPrice, setShowPrice] = useState(true)

  useEffect(() => {}, [ReduxSearchData])
  const [currentPage, setCurrentPage] = useState(1)
  const TransferPerPage = 15 // Number of hotels to display per page
  const indexOfLastTransfer = currentPage * TransferPerPage
  const indexOfFirstTransfer = indexOfLastTransfer - TransferPerPage
  const currentHotels = hotelsListing.slice(
    indexOfFirstTransfer,
    indexOfLastTransfer
  )
  const pagesCount = Math.ceil(hotelsListing.length / TransferPerPage)
  const validCurrentPage = currentPage < 1 ? 1 : currentPage
  const maxPagesToShow = 8

  const startHotelIndex = indexOfFirstTransfer + 1
  const endHotelIndex = Math.min(indexOfLastTransfer, hotelsListing.length)
  const paginate = pageNumber => {
    window.scrollTo(0, 0)
    setCurrentPage(pageNumber)
  }

  const renderPaginationItems = () => {
    const items = []
    let startPage = currentPage - Math.floor(maxPagesToShow / 2)
    startPage = Math.max(startPage, 1)
    const endPage = Math.min(startPage + maxPagesToShow - 1, pagesCount)

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => paginate(i)} href='#'>
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }

    return items
  }
  useEffect(() => {
    // Adjust the current page if it exceeds the total number of pages
    if (currentPage < 1) {
      setCurrentPage(1)
    } else if (currentPage > pagesCount) {
      setCurrentPage(pagesCount)
    }
  }, [currentPage, pagesCount])

  useEffect(() => {
    setCurrentPage(1)
  }, [hotelsListing])

  useEffect(() => {
    if (hotelid !== '') {
      setLoadingpage(true)
      if (typeof hotelid === 'number' || typeof hotelid === 'string') {
        filteredHotels = hotels.hotels_list.filter(
          x => Number(x.hotel_id) === Number(hotelid)
        )
        setHotelsListing(filteredHotels)
        setLoadingpage(false)
      } else if (hotelid.type === 'Stars') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          const hotelRating = hotel.stars_rating
          if (hotelRating !== '') {
            return Object.keys(hotelid).some(
              ratingKey => Number(hotelRating) === Number(hotelid[ratingKey])
            )
          }
        })
        setHotelsListing(filteredHotels)
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      } else if (hotelid.type === 'meal') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          // Check if any room in the hotel's rooms_options array has the specified boardtype
          return hotel.rooms_options.some(room => {
            for (const mealKey in hotelid) {
              if (room.board_id === hotelid[mealKey]) {
                return true // Found a match, include the hotel
              }
            }
            return false // No match found for any meal key
          })
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      } else if (hotelid.type === 'facility') {
        const filteredHotels = []
        currentHotels.forEach(hotel => {
          Facilityfilter(hotel.hotel_id, hotel.hotel_provider, metaData => {
            // Check if the hotel has the desired facilities (e.g., wifi and safe).
            for (const mealKey in hotelid) {
              if (metaData.facilities.includes(hotelid[mealKey])) {
                filteredHotels.push(hotel)
              }
            }
          })
        })
        setLoadingpage(false)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setHotelsListing(filteredHotels)
      } else if (hotelid.type === 'price') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          const price = hotel.min_price
          return price >= hotelid.min && price <= hotelid.max
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      }
    }
  }, [hotelid])

  const Facilityfilter = async (hotelId, provider, callback) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }
    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      callback(response.data.details_data)
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    setSearchData(prevdata => ({
      ...prevdata,
      adult: ReduxSearchData.adult,
      child: ReduxSearchData.child,
      checkin: moment(ReduxSearchData.check_in).format('Do MMM '),
      checkout: moment(ReduxSearchData.check_out).format('Do MMM ')
    }))
  }, [])

  useEffect(() => {
    const fetchCurrencies = async () => {
      const currencies = [];
      hotels.hotels_list.forEach(hotel => {
        if (!currencies.includes(hotel.hotel_curreny)) {
          currencies.push(hotel.hotel_curreny);
        }
      });
      const currencyResponse =await AllCurrency(currencies);
      const result =makeArrayNull(currencyResponse)
      if(result===null){
        localStorage.setItem('AllHotelCurr',null);
      }else{
        localStorage.setItem('AllHotelCurr',JSON.stringify(currencyResponse));
      }
      // setCurrencyData(currencyResponse);
      setSearchData(prevdata => ({
        ...prevdata,
        adult: ReduxSearchData.adult,
        child: ReduxSearchData.child,
        checkin: moment(ReduxSearchData.check_in).format('Do MMM '),
        checkout: moment(ReduxSearchData.check_out).format('Do MMM ')
      }));
    };
    fetchCurrencies();
  }, []);
  function makeArrayNull(arr) {
    // Check if every element in the array is an empty array
    if (arr.every(element => Array.isArray(element) && element.length === 0)) {
      return null;
    }
    return arr;
  }
  const RoomDetailPage = async (id, indexNO) => {
    const hotelRoomdetail = hotels.hotels_list.filter(
      item => item.hotel_id == id
    )
    const index = hotels.hotels_list.findIndex(item => item.hotel_id === id)

    setLoadingpage(true)
    const data = {
      token: apitoken,
      hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
      hotel_code: String(id),
      lat: ReduxSearchData.lat,
      long: ReduxSearchData.long
    }
    try {
      // _Live
      const response = await Axios.post(
        endpoint + '/api/hotels/details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      // Handle the API response here
      dispatch(fetchHotelDetail(response.data.hotel_details))
      navigate(`/hotel_detail/${id}`, { state: { index } })
      setLoadingpage(false)
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }

  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }

    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      setHotelDetails(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    currentHotels.forEach(hotel => {
      if (!hotelDetails[hotel.hotel_id]) {
        fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
      }
    })
  }, [currentPage, hotelsListing])
  const AllCurrency =async currencyArray => {
    const token = CurrencyConverter();
    const requests = currencyArray.map(currencynew => {
      const config = {
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {}
      };

      return Axios.request(config)
        .then(response => response.data)
        .catch(error => {
          console.error(error);
          setShowPrice(false);
          return []; // Return null for failed requests
        });
    });
    const results = await Promise.all(requests);
    var newdatcurr=results.filter(response => response !== null);
    return newdatcurr;
  };
  const renderPrice = (price,currency) => {
    var currencyData= JSON.parse(localStorage.getItem('AllHotelCurr'));
    var selectedcurr=localStorage.getItem('DefaultCurrency');
    
    if(selectedcurr===currency|| currencyData===null ){
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price)
    }else{
      var filterroomcurrdata=currencyData.filter(item=> item.base_code===currency);
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = (Number(gbpprice) * Number(price))
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = (Number(gbpprice) * Number(price))
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = (Number(gbpprice2) * Number(baseprice1))
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }
      return baseprice
    }
  };
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2);
    }
    return finalpricemarkup
  };
  return (
    <>
      {Loadingpage && <Loading />}
      <div>
        <div className='filter-page__content'>
          <div className='fw-bold mb-2'>
            Showing {startHotelIndex} to {endHotelIndex} of{' '}
            {hotelsListing.length} hotels
          </div>
          <div className='filter-item-wrapper ' id='tours_filter'>
            <div className='row'>
              {currentHotels.map((item, index) => (
                <div key={item.hotel_id} className='col-sm-6 col-md-4 mt-5'>
                  <div className=''>
                    <div className='hotel-list-top'>
                      <div class='hotel-list-img'>
                        {/* <p className="text-center show-provider">{item.hotel_provider}</p> */}
                        {hotelDetails[item.hotel_id] &&
                        hotelDetails[item.hotel_id].details_data &&
                        hotelDetails[item.hotel_id].details_data.image ? (
                          <div>
                            <img
                              className='tour-img'
                              src={
                                hotelDetails[item.hotel_id].details_data.image
                              }
                              alt=''
                            />
                          </div>
                        ) : (
                          // <ShimmerThumbnail height={250} />
                          <img src={iLoading} />
                        )}
                        <div class='book-hotel d-flex flex-row-reverse'>
                          <button
                            className='btn  form-control'
                            onClick={() => RoomDetailPage(item.hotel_id, index)}
                          >
                            Book Now
                          </button>
                          {showPrice ? (
                            <button className='btn '>
                              From{' '}
                              <super>
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  ),item?.hotel_curreny
                                )}
                              </super>
                              {/* <sub>PP</sub> */}
                            </button>
                          ) : (
                            <button className='btn'>
                              <super>
                                {item?.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                )}
                              </super>
                              {/* <sub>PP</sub> */}
                            </button>
                          )}
                        </div>
                      </div>
                      <h5 className='card-title mt-2'>
                        <a
                          onClick={() => RoomDetailPage(item.hotel_id, index)}
                          className='p-card-title'
                        >
                          {item.hotel_name}
                        </a>
                      </h5>
                      {hotelDetails[item.hotel_id] && (
                        <div class='item-address'>
                          <i class='awe-icon awe-icon-marker-2'>
                            <FontAwesomeIcon icon={faGlobe} />
                          </i>{' '}
                          {hotelDetails[item.hotel_id].details_data?.address}{' '}
                        </div>
                      )}
                      <p className='card-star'>
                        {item.stars_rating === '' ? (
                          <span className='fw-bold'>No Rating</span>
                        ) : (
                          Array(item.stars_rating)
                            .fill(0)
                            .map((_, index) => (
                              <i key={index} className='fa fa-star'>
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            ))
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='row pagination_Div mt-5'>
            <div className='col-md-6 col-sm-12  col-12'>
              <div className='fw-bold  m-2'>
                Showing {startHotelIndex} to {endHotelIndex} of{' '}
                {hotelsListing.length} hotels
              </div>
            </div>
            <div className='col-md-6 col-sm-12 col-12'>
              <Pagination aria-label='Page navigation example '>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => paginate(validCurrentPage - 1)}
                  />
                </PaginationItem>
                {renderPaginationItems()}
                <PaginationItem disabled={currentPage === pagesCount}>
                  <PaginationLink
                    next
                    onClick={() => paginate(validCurrentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HotelCard
