import React, { useState, useEffect } from 'react'
import logo from './logo.png'
import Axios from 'axios'
import ReactDOM from 'react-dom'
import { json, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faSignIn,
  faBars,
  faTimes,
  faChevronDown,
  faCross,
  faClose,
  faAngleRight,
  faArrowDown,
  faAngleDown,
  faMosque,
  faKaaba
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from '../../i18n'
import LanguageSwitcher from '../Activity/TranslationTrigger'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'
function Navbar () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigation = useNavigate()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [modalOpen, setModalOpen] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [userCountry, setUserCountry] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownCat, setDropdownCat] = useState([])
  const [isDropdownOpen3, setDropdownOpen3] = useState(false)
  const [isDropdownOpen2, setDropdownOpen] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('GBP')

  // This function put query that helps to
  // change the language
  const currencyData = [
    { country: 'United Kingdom', currency: 'GBP' },
    { country: 'United States', currency: 'USD' },
    { country: 'Eurozone', currency: 'EUR' },
    { country: 'Saudi Arabia', currency: 'SAR' },
    { country: 'United Arab Emirates', currency: 'AED' },
    { country: 'Pakistan', currency: 'PKR' },
    { country: 'India', currency: 'INR' }
  ];
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen2)
  }
  const toggleDropdown1 = () => {
    setDropdownOpen3(!isDropdownOpen3)
  }

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }
  const buttonIconClass = isMobileNavOpen
    ? 'navbar-toggler-icon-cross'
    : 'navbar-toggler-icon'
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  useEffect(() => {
    GetCatagories()
    fetchData()
    AllCurrency()
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
      CurrencyCalucaltion(storedCurrency)
    } else {
      dispatch(CurrencyRates(undefined))
    }
  }, [])

  const SearchValue = e => {
    var filteredList = countryList.filter(item =>
      item.currency.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setUserCountry(filteredList)
    setSearchValue(e.target.value)
  }

  async function fetchData () {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = (index, currency) => {
    toggleModal()
    setActiveIndex(index)
    setSelectedCurrency(currency)
    CurrencyCalucaltion(currency)
    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c
        dispatch(CurrencyRates(response.data))
      })
      .catch(error => {
        if (localStorage.getItem('selectedCurrency')) {
          localStorage.removeItem('selectedCurrency')
        }
        dispatch(CurrencyRates(undefined))
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        dispatch(AllCurrencyRates(response.data))
      })
      .catch(error => {
        dispatch(AllCurrencyRates(undefined))
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.setItem(
        'Catogories',
        JSON.stringify(response.data.categories)
      )
      setDropdownCat(response.data.categories)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const FindPackageDetail = id => {
    toggleDropdown()
    navigation('/umrah_packages', { state: id })
  }
  const languages = [
    { value: 'en', text: 'English' },
    { value: 'ar-SA', text: 'Arabic' }
  ]

  return (
    <>
      <header>
        <div className='navbar-top-right '>
          <div className='d-flex hide-m '>
            <FontAwesomeIcon icon={faPhone} />
            <h6>111 5555 2222</h6>
          </div>
          <div className='d-flex  '>
            <button
              onClick={toggleModal}
              className='btn btn-primary currency-btn'
            >
              {selectedCurrency}
            </button>
            <I18nextProvider i18n={i18n}>
              <LanguageSwitcher language='arabic' />
            </I18nextProvider>
          </div>
        </div>

        <nav
          id='navbar'
          className={
            'navbar-expand-lg    ' +
            (isMobileNavOpen ? 'navbar-mobile' : 'navbar')
          }
        >
          <img src={logo} alt='Logo' />
          <button
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            className='navbar-toggler'
          >
            {isMobileNavOpen ? (
              <span
                style={{ color: 'white' }}
                onClick={toggleMobileNav}
                className='mobile-nav-toggle '
              >
                {' '}
                <FontAwesomeIcon icon={faClose} />
              </span>
            ) : (
              <span
                onClick={toggleMobileNav}
                className='mobile-nav-toggle navbar-toggler-icon'
              ></span>
            )}
          </button>
          <ul className='navbar-nav navbar-link  ml-auto'>
            <li className='nav-item nav-link scrollto  active'>
              <NavLink className='nav-link' to='/'>
                {t('Home')}
              </NavLink>
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/makkah'>
                {t('Makkah')}
              </NavLink>
              <span class=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/medina'>
                {t('Medina')}
              </NavLink>
              <span class=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/about-us'>
                {t('About Us')}
              </NavLink>
              <span class=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>

            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/contact-us'>
                {t('Contact Us')}
              </NavLink>
              <span className=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
          </ul>
        </nav>
      </header>

      {/*********** Cuurency  Model ****************/}
      <Modal
        style={{ maxWidth: '90%' }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Select Your Currency</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>
              Where applicable, prices will be converted to—and shown in—the
              currency you select. The currency you pay in may differ based on
              your reservation, and a service fee may also apply.
            </p>
            <div className='border-line mt-2 mb-2'></div>
            {/* <div className='row mt-2'>
         <h5>Suggested for you</h5>
       </div> */}
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5>All Currencies</h5>
              </div>
              {currencyData.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.country} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Navbar
